import {  Avatar, Space, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import EmptyData from "../../../../components/Admin/EmptyData";
import { getReports } from "../../../../store/Admin/actions/sessions";
import user from "../../../../assets/images/user.png";
import useAppParams from "../../../../hooks/useAppParams";
import SessionsFilter from "../Filter";
import { Link } from "react-router-dom";
import { FaRegEye } from "react-icons/fa";
const SessionsReportsList = () => {
  const dispatch = useDispatch();

  const { UrlParams, handleSearch } = useAppParams({});
  const [data, setData] = useState([]);
  const { dataReports, loading } = useSelector(
    (state) => state.reports
  );
  const [selectedRows, setSelectedRows] = useState([]);

  useEffect(() => {
    if (UrlParams) {
      dispatch(
        getReports({
          params: { ...UrlParams },
        })
      );
    }
  }, [dispatch, UrlParams]);

  useEffect(() => {
    setData(
      dataReports?.data?.map((row) => ({
        key: row.id,
        id: row.id,
        name_beneficiary: row?.beneficiary?.full_name_ar,
        avatar_beneficiary: row?.beneficiary?.avatar,
        name_adviser: row?.adviser?.full_name_ar,
        avatar_adviser: row?.adviser?.avatar,
        topic: row?.topic,
        date: row?.created_at,
      }))
    );
  }, [dataReports]);

  const ChangePagination = (number) => {
    handleSearch({
      fields: {
        page: number,
      },
    });
  };

  const rowSelection = {
    onChange: (selectedRowKeys) => {
      setSelectedRows(selectedRowKeys);
    },
  };
  const columns = [
    {
      title: "إسم المستفيد",
      dataIndex: ["name_beneficiary" , "avatar_beneficiary"],
      render: (index, row) => (
        <div key={index}>
          <Avatar
            size={40}
            src={row?.avatar_beneficiary ? row?.avatar_beneficiary : user}
          />{" "}
          {row?.name_beneficiary}
        </div>
      ),
    },
    {
      title: "إسم المستشار",
      dataIndex: ["name_adviser" , "avatar_adviser"],
      render: (index, row) => (
        <div key={index}>
        <Avatar
          size={40}
          src={row?.avatar_adviser ? row?.avatar_adviser : user}
        />{" "}
        {row?.name_adviser}
      </div>
      ),
    },
    {
      title: "تاريخ الحجز ",
      dataIndex: "date",
    },
    {
      title: "الاجراءات",
      key: "action",
      render: (index) => (
        <span>
          <Space size="middle">
            <Link className="text-info" to={`/reports/${index?.id}/show`}>
              <FaRegEye />
            </Link>
          </Space>
        </span>
      ),
    },
  ];
  let locale = {
    emptyText: <EmptyData />,
  };

  return (
    <>
     <SessionsFilter />
      {/* <div className="mb-15 group-btn">
        <button
          className="btn btn-blocked"
          onClick={() => {
            confirm({ type: "multible" });
          }}
        >
          <span className="icon">
            <FaBan />
          </span>
          تعطيل
        </button>
      </div> */}
      <Table
        locale={locale}
        size="small"
        rowSelection={{
          ...rowSelection,
        }}
        columns={columns}
        loading={loading}
        dataSource={data}
        pagination={{
          defaultCurrent:1,
          current: Number(UrlParams?.page ? UrlParams?.page : 1),
          pageSize: 10,
          total: dataReports?.pagination?.total,
          onChange: (page) => {
            ChangePagination(page);
          },
        }}
      />
    </>
  );
};

export default SessionsReportsList;
