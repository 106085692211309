import { Routes, Route } from "react-router-dom";
// import BlogCreate from "../pages/Admin/Blog/Create";
// import BlogEdit from "../pages/Admin/Blog/Edit";
// import BlogList from "../pages/Admin/Blog/List";
// import BlogSingle from "../pages/Admin/Blog/Single";
import FaqCreate from "../pages/Admin/Faq/Create";
import FaqEdit from "../pages/Admin/Faq/Edit";
import FaqList from "../pages/Admin/Faq/List";
import Login from "../pages/Admin/Login";
import PageList from "../pages/Admin/page/List";
import PageNotFound from "../pages/Admin/PageNotFound";
import SessionsList from "../pages/Admin/Sessions/List";
// import UsersCreate from "../pages/Admin/Users/Create";
// import UsersList from "../pages/Admin/Users/List";
import WalletList from "../pages/Admin/Wallet/List";
import PrivateRoutes from "./ProtectedRoute";
import NotificationsCreate from "../pages/Admin/Notifications/Create";
import NotificationsLists from "../pages/Admin/Notifications/Lists";
import BeneficiaryUsersList from "../pages/Admin/Users/Beneficiary/List";
import BeneficiaryUsersCreate from "../pages/Admin/Users/Beneficiary/Create";
import AdviserUsersList from "../pages/Admin/Users/Adviser/List";
import AdviserUsersCreate from "../pages/Admin/Users/Adviser/Create";
import SocialMediaLists from "../pages/Admin/SocialMedia/Lists";
import SocialMediaCreate from "../pages/Admin/SocialMedia/Create";
import SocialMediaEdit from "../pages/Admin/SocialMedia/Edit";
// import BeneficiaryUsersUpdate from "../pages/Admin/Users/Beneficiary/Edit";
// import AdviserUsersEdit from "../pages/Admin/Users/Adviser/Edit";
import ManagementUsersList from "../pages/Admin/Users/Management/List";
import ManagementUsersCreate from "../pages/Admin/Users/Management/Create";
import Dashboard from "../pages/Admin/Dashboard";
import FieldsList from "../pages/Admin/Fields/List";
import UserActivationList from "../pages/Admin/UserActivation/List";
// import HomeBlogSingle from "../pages/website/BlogSingle";
import UsersDetails from "../pages/Admin/Users/Details";
import MessagesList from "../pages/Admin/Messages/List";
import MessagesDetails from "../pages/Admin/Messages/Details";
import SubCategories from "../pages/Admin/Fields/SubCategories";
import ActivationDetails from "../pages/Admin/UserActivation/Details";
import CommentList from "../pages/Admin/Comments/Lists";
import Financial from "../pages/Admin/Wallet/Financial";
import UsersActivity from "../pages/Admin/UsersActivity/List";
import SessionPrice from "../pages/Admin/SessionPrice/Create";
import LecturesList from "../pages/Admin/Lectures/List";
import LecturesCreate from "../pages/Admin/Lectures/Create";
import DiscountCouponList from "../pages/Admin/CouponDiscount/List";
import DiscountCouponCreate from "../pages/Admin/CouponDiscount/Create";
import AdvertisementsList from "../pages/Admin/Advertisements/List";
import AdvertisementsCreate from "../pages/Admin/Advertisements/Create";
// import SessionMeeting from "../pages/website/SessionMeeting";
import CompanyCouponsList from "../pages/Admin/CompanyCoupon/List";
import CompanyCouponCreate from "../pages/Admin/CompanyCoupon/Create";
import CompaniesList from "../pages/Admin/Companies/List";
import CompanyCreate from "../pages/Admin/Companies/Create";
import JoinCompanyRequestsList from "../pages/Admin/JoinCompanyRequests/List";
import JoinCompanyRequestEdit from "../pages/Admin/JoinCompanyRequests/Create";
import SessionsReportsList from "../pages/Admin/SessionsReports/List";
import ReportDetails from "../pages/Admin/SessionsReports/Details";
import ErrorLogsList from "../pages/Admin/ErrorLogs/List";
import NotificationLogsList from "../pages/Admin/NotificationLogs/List";
import TermsAndConditions from "../pages/website/Terms/terms";
import PrivacyPolicy from "../pages/website/Policy/policy";
// import BeneficiaryUsersUpdate from "../pages/Admin/Users/Beneficiary/Edit";
// import MessagesList from "../pages/Admin/Messages/List";
// import ManagementUsersEdit from "../pages/Admin/Users/Management/Edit";

const Routers = () => {
  return (
    <Routes>
      {/* <Route path="/" element={<Home />}></Route> */}
      {/* Start SessionMeeting */}
      {/* <Route path="/sessions/meeting/:id/:user_token" element={<SessionMeeting />}></Route> */}
      {/* End SessionMeeting */}
      {/* Start Terms */}
      <Route path="/terms" element={<TermsAndConditions />}></Route>
      {/* End Terms */}
      {/* Start Policy */}
      <Route path="/policy" element={<PrivacyPolicy />}></Route>
      {/* End Policy */}
      {/* <Route path="/blog/:id" element={<HomeBlogSingle />}></Route> */}
      <Route path="/login" element={<Login />}></Route>
      <Route element={<PrivateRoutes/>}>
        {/* Dashboard as home */}
        <Route path="/" element={<Dashboard />}></Route>
        
        {/* Start users */}
        <Route path="/adviser/users" element={<AdviserUsersList />}></Route>
        <Route path="/adviser/users/create" element={<AdviserUsersCreate />}></Route>
        <Route path="/adviser/users/:id/edit" element={<AdviserUsersCreate />}></Route>

        <Route path="/beneficiary/users" element={<BeneficiaryUsersList />}></Route>
        <Route path="/beneficiary/users/create" element={<BeneficiaryUsersCreate />}></Route>
        <Route path="/beneficiary/users/:id/edit" element={<BeneficiaryUsersCreate />}></Route>

        <Route path="/management/users" element={<ManagementUsersList />}></Route>
        <Route path="/management/users/create" element={<ManagementUsersCreate />}></Route>
        <Route path="/management/users/:id/edit" element={<ManagementUsersCreate />}></Route>
        
        <Route path="/users/:id/show" element={<UsersDetails />}></Route>
        
        {/* End users */}
        {/* Start Sessions */}
        <Route path="/sessions" element={<SessionsList />}></Route>
        {/* End Sessions */}
        {/* Start ErrorLogs */}
        <Route path="/notification-logs" element={<NotificationLogsList />}></Route>
        {/* End ErrorLogs */}
        {/* Start ErrorLogs */}
        <Route path="/error-logs" element={<ErrorLogsList />}></Route>
        {/* End ErrorLogs */}
        {/* Start Sessions Reports */}
        <Route path="/reports" element={<SessionsReportsList />}></Route>
        <Route path="/reports/:id/show" element={<ReportDetails />}></Route>
        {/* End Sessions Reports */}
        {/* Start Lectures */}
        <Route path="/lectures" element={<LecturesList />}></Route>
        <Route path="/lectures/create" element={<LecturesCreate />}></Route>
        <Route path="/lectures/:id/edit" element={<LecturesCreate />}></Route>
        {/* END Lectures */}
        {/* Start Advertisements */}
        <Route path="/advs" element={<AdvertisementsList />}></Route>
        <Route path="/advs/create" element={<AdvertisementsCreate />}></Route>
        <Route path="/advs/:id/edit" element={<AdvertisementsCreate />}></Route>
        {/* END Advertisements */}
        {/* Start Wallet */}
        <Route path="/wallet" element={<WalletList />}></Route>
        <Route path="/financial" element={<Financial />}></Route>
        {/* End Wallet */}
        {/* Start Wallet */}
        <Route path="/notifications" element={<NotificationsLists />}></Route>
        <Route path="/notifications/create" element={<NotificationsCreate />}></Route>
        {/* End Wallet */}
        {/* Start Wallet */}
        <Route path="/social-media" element={<SocialMediaLists />}></Route>
        <Route path="/social-media/create" element={<SocialMediaCreate />}></Route> 
        <Route path="/social-media/:id/edit" element={<SocialMediaEdit />}></Route> 
        {/* End Wallet */}
                
        {/* Start Faq */}   
        <Route path="/faq" element={<FaqList />}></Route>
        <Route path="/faq/create" element={<FaqCreate />}></Route>
        <Route path="/faq/edit/:id" element={<FaqEdit />}></Route>
        {/* End Faq */}

        {/* Start message */}   
        <Route path="/:typeMessage" element={<MessagesList />}></Route>
        <Route path="/message/:id/show" element={<MessagesDetails />}></Route>
        {/* End message */}

        {/* Start fields */}   
        <Route path="/fields" element={<FieldsList />}></Route>
        <Route path="/fields/:id/sub_categories" element={<SubCategories />}></Route>
        
        {/* End fields */}

        <Route path="/user-activation" element={<UserActivationList />}></Route>
        <Route path="/user-activation/:id/show" element={<ActivationDetails />}></Route>


        <Route path="/comments" element={<CommentList />}></Route>


        <Route path="/users-activity" element={<UsersActivity />}></Route>


        <Route path="/session-price" element={<SessionPrice />}></Route>

        {/* Start DiscountCoupon */}
        <Route path="/coupons" element={<DiscountCouponList />}></Route>
        <Route path="/coupons/create" element={<DiscountCouponCreate />}></Route>
        <Route path="/coupons/:id/edit" element={<DiscountCouponCreate />}></Route>
        {/* END DiscountCoupon */}

        {/* Start CompanyCoupon */}
        <Route path="/company-coupons/:company_id" element={<CompanyCouponsList />}></Route>
        <Route path="/company-coupons/:company_id/create" element={<CompanyCouponCreate />}></Route>
        <Route path="/company-coupons/:company_id/:id/edit" element={<CompanyCouponCreate />}></Route>
        {/* END CompanyCoupon */}

        {/* Start Company */}
        <Route path="/companies" element={<CompaniesList />}></Route>
        <Route path="/companies/create" element={<CompanyCreate />}></Route>
        <Route path="/companies/:id/edit" element={<CompanyCreate />}></Route>
        {/* END Company */}

        {/* Start JoinCompanyRequestsList */}
        <Route path="/companies/requests/list" element={<JoinCompanyRequestsList />}></Route>
        <Route path="/companies/requests/list/:id/:adviser_id" element={<JoinCompanyRequestEdit />}></Route>
        {/* END JoinCompanyRequestsList */}

        <Route path="/page/:type" element={<PageList />}></Route>
        <Route path="pageNotFound" element={<PageNotFound />}></Route>
        <Route path="*" element={<PageNotFound />}></Route>
      </Route>
    </Routes>
  );
};

export default Routers;
