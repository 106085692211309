import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  InputNumber,
  Select,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../../../../components/Admin/Loading/Loading";
import { getCompanyCouponsForSelection } from "../../../../store/Admin/actions/CompanyCoupon";
import UsersDetails from "../../Users/Details";
import { updateRequestStatus } from "../../../../store/Admin/actions/JoinCompanyRequests";
import useAppParams from "../../../../hooks/useAppParams";

const { Option } = Select;

const JoinCompanyRequestEdit = () => {
  const { id, adviser_id } = useParams();
  const { UrlParams } = useAppParams({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [status, setStatus] = useState(UrlParams?.defStatus);
  const { dataCouponsList, loading: couponsLoading } = useSelector((state) => state.companyCouponsSelection);
  const { loading: updateStatusLoading } = useSelector((state) => state.updateRequestStatus);

  useEffect(() => {
    if (UrlParams) {
      dispatch(getCompanyCouponsForSelection(UrlParams.company_id));
    }
  }, [dispatch, UrlParams]);

  const onFinish = (values) => {
    values = {
      ...values,
      id: id,
    };
    const formData = new FormData();
    Object.keys(values).forEach((element) =>{
      if(values[element] === undefined || values[element] === null){
        delete values[element]
      }else{
        formData.append(element, values[element])
      }
    });

    dispatch(
      updateRequestStatus({
        values: values,
        callback: () => {
          form.resetFields();
          navigate(`/companies/requests/list`);
        },
      })
    );
  };

  const handleChange = (value) => {
    setStatus(value);
  };

  return (
    <Card>
      {couponsLoading ? (
        <Loading />
      ) : UrlParams?.defStatus !== 'PENDING' ? (<></>) : (
        <Form
          size="large"
          form={form}
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 24,
          }}
          onFinish={onFinish}
          autoComplete="off"
          scrollToFirstError={{
            behavior: "smooth",
            block: "center",
          }}
        >
          <Col span={24} md={12}>
            <Form.Item
              name="status"
              label="تعديل حالة الطلب"
              rules={[
                {
                  required: true,
                  message: "الحقل مطلوب",
                },
              ]}
            >
              <Select placeholder="الحالة" onChange={handleChange}>
                <Option value="ACCEPTED">القبول</Option>
                <Option value="REJECTED">الرفض</Option>
              </Select>
            </Form.Item>
            {
              status !== 'ACCEPTED' ? (<></>): (
                <Form.Item
                  name="coupon_id"
                  rules={[
                    {
                      required: true,
                      message: "الحقل مطلوب",
                    },
                  ]}
                >
                  <Select showSearch filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                    placeholder="الكوبونات"
                    loading={couponsLoading}
                  >
                    {dataCouponsList?.map((item, index) => (
                      <Option value={item.id}>{item.code}</Option>
                    ))}
                  </Select>
                </Form.Item>
              )
            }
            {
              status !== 'ACCEPTED' ? (<></>) : (
                
                <Form.Item
                  label="سعر الجلسة الواحدة"
                  name="session_price"
                >
                  <InputNumber min={0} style={{width:"100%"}}  />
                </Form.Item>
              )
            }
          </Col>
          <div className="text-center">
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                loading={updateStatusLoading}
              >
                تأكيد
              </Button>
            </Form.Item>
          </div>
        </Form>
      )}
      <UsersDetails id={adviser_id}></UsersDetails>
    </Card>
  );
};

export default JoinCompanyRequestEdit;
