import { Avatar, Space, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import EmptyData from "../../../../components/Admin/EmptyData";
import { FaRegEye } from "react-icons/fa";
import useAppParams from "../../../../hooks/useAppParams";
import RequestsFilter from "../Filter";
import { getRequests } from "../../../../store/Admin/actions/JoinCompanyRequests";

const JoinCompanyRequestsList = () => {
  const dispatch = useDispatch();

  const { UrlParams, handleSearch } = useAppParams({});
  const [data, setData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const { dataRequests, loading } = useSelector((state) => state.requests);
  useEffect(() => {
    if (UrlParams) {
      dispatch(
        getRequests({
          params: { ...UrlParams },
        })
      );
    }
  }, [dispatch, UrlParams]);

  useEffect(() => {
    setData(
      dataRequests?.requests?.map((row) => ({
        key: row?.id,
        id: row?.id,
        adviser_id: row?.adviser?.id,
        adviser_name: row?.adviser?.full_name_ar,
        adviser_avatar: row?.adviser?.avatar,
        company_id: row?.company?.id,
        company_name: row?.company?.name,
        company_logo: row?.company?.logo,
        status: row?.status,
        created_at: row?.created_at,
      }))
    );
  }, [dataRequests]);

  const ChangePaginationAdv = (number) => {
    handleSearch({
      fields: {
        page: number,
      },
    });
  };

  const rowSelection = {
    onChange: (selectedRowKeys) => {
      setSelectedRows(selectedRowKeys);
    },
  };

  const getStatusData = (status) => {
    const result = {};
    switch (status) {
      case 'ACCEPTED': result['text'] = 'مقبول';
                       result['color'] = 'text-success';
                       break;
      case 'REJECTED': result['text'] = 'مرفوض';
                       result['color'] = 'text-danger';
                       break;
      default : result['text'] = 'جاري';
                result['color'] = 'text-primary';
                break;
    }

    return result;
  }

  const columns = [
    {
      title: "اسم المستشار",
      dataIndex: ["adviser_name", "adviser_avatar"],
      render: (index, row) => (
        <div key={index}>
        <Avatar
          size={40}
          src={row?.adviser_avatar}
        />{" "}
        {row?.adviser_name}
      </div>
      ),
    },
    {
      title: "اسم الشركة",
      dataIndex: ["company_name", "company_logo"],
      render: (index, row) => (
        <div key={index}>
        <Avatar
          size={40}
          src={row?.company_logo}
        />{" "}
        {row?.company_name}
      </div>
      ),
    },
    {
      title: "الحالة",
      dataIndex: "status",
      render: (row) => (
        <div className={getStatusData(row).color}>
          {getStatusData(row).text}
        </div>
      ),
    },
    {
      title: "التاريخ",
      dataIndex: "created_at",
    },
    {
      title: "الاجراءات",
      key: "action",
      render: (value) => (
        <Space size="middle">
          <Link
            className="text-info"
            to={`/companies/requests/list/${value?.id}/${value?.adviser_id}?company_id=${value?.company_id}&defStatus=${value?.status}`}
          >
            <FaRegEye />
          </Link>
        </Space>
      ),
    },
  ];

  return (
    <>
      <RequestsFilter />
      <Table
        locale={{ emptyText: <EmptyData /> }}
        size="small"
        rowSelection={{
          ...rowSelection,
        }}
        columns={columns}
        loading={loading}
        dataSource={data}
        pagination={{
          current: Number(UrlParams?.page ? UrlParams?.page : 1),
          total: dataRequests?.data?.total,
          onChange: (page) => {
            ChangePaginationAdv(page);
          },
        }}
      />
    </>
  );
};

export default JoinCompanyRequestsList;
