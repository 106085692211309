import { Outlet, Navigate, useLocation } from "react-router-dom";

const PrivateRoutes = () => {
  const auth = JSON.parse(localStorage.getItem("user"));
  const location = useLocation();

  // If not authenticated, redirect to login with the attempted path
  if (!auth) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return <Outlet />;
};

export default PrivateRoutes;
