import React from 'react'
import { Alert, Button, Col, Form, Image, Input, notification, Row } from 'antd'
import imageLogin from '../../../assets/images/image-login.png'
import { FaAt } from "react-icons/fa";
import { MdLock  } from "react-icons/md";
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useNavigate, useLocation } from 'react-router-dom';
import { login } from '../../../store/Admin/actions/login';

const Login = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { error, loading } = useSelector((state) => state.logedIn);

  const openNotificationWithIcon = type => {
    notification[type]({
      message: 'تم تسجيل دخولك بنجاح',
      duration: 2
    })
  }

  const onFinish = (values) => {
    dispatch(
      login({
        values,
        callback: () => {
          openNotificationWithIcon('success');
          const from = location.state?.from?.pathname || "/";
          navigate(from);
        },
      })
    );
  };

  if (JSON.parse(localStorage.getItem("user"))) {
    return <Navigate to="/" />;
  }

  return (
    <div className='min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8'>
      <div className='container max-w-7xl mx-auto'>
        <Row gutter={[32, 32]} align="middle" justify="center">
          <Col span={24} lg={12}>
            <div className="bg-white p-8 rounded-lg shadow-md">
              <h2 className="text-3xl font-bold text-center mb-8">تسجيل الدخول</h2>
              {error ? <Alert message={error} className="mb-6" type="error" /> : null}
              <Form
                size='large'
                labelCol={{
                  span: 24,
                }}
                wrapperCol={{
                  span: 24,
                }}
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
                autoComplete="off"
                className="space-y-6"
              >
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: 'الحقل مطلوب',
                    },
                    {
                      type: 'email',
                      message: 'ادخل بريد الكتروني صحيح',
                    },
                  ]}
                >
                  <Input 
                    prefix={<FaAt className="text-gray-400" />} 
                    placeholder='البريد الإلكتروني'
                    className="rounded-md" 
                  />
                </Form.Item>

                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: 'الحقل مطلوب',
                    },
                  ]}
                >
                  <Input.Password 
                    prefix={<MdLock className="text-gray-400" />} 
                    placeholder='كلمة المرور'
                    className="rounded-md" 
                  />
                </Form.Item>

                <Form.Item>
                  <Button 
                    loading={loading} 
                    block 
                    type="primary" 
                    htmlType="submit"
                    className="h-12 rounded-md"
                  >
                    تسجيل الدخول
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </Col>
          <Col span={24} lg={12}>
            <Image 
              src={imageLogin} 
              preview={false} 
              className="w-full h-auto object-contain"
            />
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default Login