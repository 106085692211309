import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import styles from "./style.module.scss";
import { Badge, Dropdown , Image } from "antd";
import { FaBars, FaBell } from "react-icons/fa";
import user from '../../../assets/images/user.png'

const HeaderPanel = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const Logout = ()=>{
    localStorage.removeItem('user')
    navigate("/login");
    window.location.reload()
  }
  const items = [
    {
      label: <a onClick={()=>{Logout()}}>تسجيل الخروج</a>,
      key: '0',
    },
  ];

  const ss = ()=>{
    props.collaps(!props?.collapsed)
  }
  return (
    <div className={`${styles["header-panel"]}`}>
      <h3 className="font-bold">
        {location.pathname === "/sessions"
          ? "الحجوزات"
          : location.pathname === "/adviser/users"
          ? "المستشارين"
          : location.pathname === "/beneficiary/users"
          ? "المستفيدين"
          : location.pathname === "/companies"

          ? "الشركات"
          : location.pathname === "/company-coupons"
          ? "كوبونات الشركات"
          : location.pathname === "/companies/requests/list"
          ? "طلبات الانضمام للشركات"
          : location.pathname === "/lectures"

          ? "المحاضرات المجانية"
          : location.pathname === "/management/users"
          ? "الادارة"
          : location.pathname === "/coupons"
          ? "كوبونات الخصم"
          : location.pathname === "/sessions"
          ? "الحجوزات"

          : location.pathname === "/notification-logs"
          ? "بيان الاشعارات"
          : location.pathname === "/error-logs"
          ? "اخطاء النظام"
          : location.pathname === "/reports"
          ? "تقارير الجلسات"
          : location.pathname === "/wallet"

          ? "المحفظة"
          : location.pathname === "/notifications"
          ? "الإشعارات"
          : location.pathname === "/notifications/create"
          ? "الإشعارات"
          : location.pathname === "/blogs"
          ? "المقالات"
          : location.pathname === "/blogs/create"
          ? "المقالات"

          : location.pathname === "/social-media"
          ? "معلومات التواصل"
          : location.pathname === "/social-media/create"
          ? "معلومات التواصل"
          : location.pathname === "/social-media/:id/edit"

          ? "معلومات التواصل"
          : location.pathname === "/faq"
          ? "الأسئلة المقترحة"
          : location.pathname === "/page/about"
          ? "الأسئلة المقترحة"
          : location.pathname === "/page/about/create"
          ? "من نحن"
          : location.pathname === "/page/policy"

          ? "سياسة الخصوصية"
          : location.pathname === "/page/terms"
          ? "الشروط والاحكام"
          : location.pathname === "/page/cancel-policy"
          ? "سياسة إلغاء الجلسة "
          : location.pathname === "/user-activation"
          ? "طلبات التفعيل "

          : location.pathname === "/messages"
          ? "الرسائل"
          : location.pathname === "/assistant-counselor"
          ? "المستشار المساعد"
          : location.pathname === "/fields"
          ? "مجالات العمل"
          : location.pathname === "/comments"

          ? "التعليقات"
          : location.pathname === "/session-price"

          ? "سعر الجلسة"
          : location.pathname === "/financial"
          ? "الحركات المالية "
          : location.pathname === "/users-activity"
          ? "نشاط المستخدمين "

          : null}
      </h3>
      <div className={`${styles["header-tolpar"]}`}>
        <div onClick={()=>ss()} className="ml-10 menu-bar"><FaBars /></div>
        {/* <Badge className="ml-10" size="small"  count={5}> */}
        <Badge className="ml-10" size="small" >
          <Link to="/notifications"><FaBell /></Link>
        </Badge>
        <Dropdown
          menu={{
            items,
          }}
          trigger={['click']}
        >
          <div onClick={(e) => e.preventDefault()}>
             <Image preview={false} src={user} width={30} />
          </div>
        </Dropdown>
      </div>
    </div>
  );
};

export default HeaderPanel;
