import "antd/dist/reset.css";
import LayoutAdmin from "./components/Admin/Layout/Layout";
import "./assets/styles/main.scss";
import { ConfigProvider } from "antd";
import { useEffect } from "react";
import { socketConnection } from "./socketConnection";
import "./index.css"
import { adjust_environment, adjust_token } from "./environment";
import Adjust from "@adjustcom/adjust-web-sdk";
import Login from "./pages/Admin/Login";
import { useLocation } from "react-router-dom";

function App() {
  const location = useLocation();

  useEffect(() => {
    Adjust.initSdk({
      appToken: adjust_token,
      environment: adjust_environment,
    });

    if(localStorage.getItem("user")){
      socketConnection();
    }
  }, []);

  // Check if current path is login
  const isLoginPage = location.pathname === "/login";

  return (
    <ConfigProvider
      direction="rtl"
      theme={{
        token: {
          colorPrimary: "#523F94",
          fontFamily: "Tajawal",
        },
      }}
    >
      {isLoginPage ? <Login /> : <LayoutAdmin />}
    </ConfigProvider>
  );
}

export default App;
